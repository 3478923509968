"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.injuryStatusColor = void 0;
function injuryStatusColor(status) {
    switch (status) {
        case "Doubtful":
            return "bg-orange";
        case "Out":
            return "bg-error-300";
        default:
            return "bg-secondary-500";
    }
}
exports.injuryStatusColor = injuryStatusColor;
