"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SeasonTypeEnum = void 0;
var SeasonTypeEnum;
(function (SeasonTypeEnum) {
    SeasonTypeEnum[SeasonTypeEnum["Regular"] = 1] = "Regular";
    SeasonTypeEnum[SeasonTypeEnum["PreSeason"] = 2] = "PreSeason";
    SeasonTypeEnum[SeasonTypeEnum["PostSeason"] = 3] = "PostSeason";
    SeasonTypeEnum[SeasonTypeEnum["OffSeason"] = 4] = "OffSeason";
    SeasonTypeEnum[SeasonTypeEnum["AllStar"] = 5] = "AllStar";
})(SeasonTypeEnum || (exports.SeasonTypeEnum = SeasonTypeEnum = {}));
