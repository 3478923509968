"use strict";
/**
 * Utils for debugging values.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.forceValue = exports.formatEmptyState = exports.isEmpty = void 0;
/**
 * @returns true if there's an empty value of any kind.
 */
function isEmpty(value) {
    if (value == null)
        return true;
    if (typeof value === "number" && isNaN(value))
        return true;
    if (value === "")
        return true;
    if (typeof value === "string" && (value.includes("undefined") || value.includes("null")))
        return true;
    return false;
}
exports.isEmpty = isEmpty;
/**
 * @returns a formatted empty string.
 */
function formatEmptyState(value) {
    switch (value) {
        case "empty":
            return "- -";
        case "n/a":
            return "N/A";
        case "tbd":
            return "TBD";
        default:
            return value;
    }
}
exports.formatEmptyState = formatEmptyState;
/**
 * @returns the desired empty state as the output if empty, otherwise returns the input.
 */
function forceValue(input, output) {
    if (isEmpty(input)) {
        return formatEmptyState(output || "n/a");
    }
    else {
        return input;
    }
}
exports.forceValue = forceValue;
