"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildOdd = exports.formatOddEnum = exports.oddOutcomeColor = exports.formatOddOutcomeShort = exports.formatOddOutcome = exports.formatOddVariantShort = exports.formatOddVariant = exports.OddOutcome = exports.pickTypeToLineType = exports.pickToOpposingOdd = exports.pickToOdd = exports.createPickPayload = exports.determineUnitRiskGain = exports.lineToPickType = exports.isSameLineType = exports.getOpposingPickAlignment = exports.getPickAlignment = exports.changePickAlignment = exports.PickType = exports.formatPickOutcomeType = exports.PickOutcomeType = exports.formatLineType = exports.toLineType = exports.ALL_LINE_TYPES = exports.LineType = exports.LINE_TYPE_OPTIONS = void 0;
const game_1 = require("./game");
exports.LINE_TYPE_OPTIONS = [
    { value: "0", label: "moneyline" },
    { value: "1", label: "spread" },
    { value: "2", label: "total" },
];
var LineType;
(function (LineType) {
    LineType[LineType["Moneyline"] = 0] = "Moneyline";
    LineType[LineType["Spread"] = 1] = "Spread";
    LineType[LineType["Total"] = 2] = "Total";
})(LineType || (exports.LineType = LineType = {}));
exports.ALL_LINE_TYPES = [LineType.Moneyline, LineType.Spread, LineType.Total];
function toLineType(input) {
    switch (input) {
        case "moneyline":
            return LineType.Moneyline;
        case "spread":
            return LineType.Spread;
        case "total":
            return LineType.Total;
    }
}
exports.toLineType = toLineType;
function formatLineType(input, short) {
    switch (input) {
        case LineType.Moneyline:
            if (short)
                return "ML";
            return "moneyline";
        case LineType.Spread:
            if (short)
                return "ATS";
            return "spread";
        default:
            if (short)
                return "O/U";
            return "total";
    }
}
exports.formatLineType = formatLineType;
var PickOutcomeType;
(function (PickOutcomeType) {
    PickOutcomeType[PickOutcomeType["Win"] = 0] = "Win";
    PickOutcomeType[PickOutcomeType["Loss"] = 1] = "Loss";
    PickOutcomeType[PickOutcomeType["Push"] = 2] = "Push";
})(PickOutcomeType || (exports.PickOutcomeType = PickOutcomeType = {}));
function formatPickOutcomeType(input, short) {
    switch (input) {
        case PickOutcomeType.Win:
            if (short)
                return "W";
            return "Win";
        case PickOutcomeType.Loss:
            if (short)
                return "L";
            return "Loss";
        default:
            if (short)
                return "P";
            return "Push";
    }
}
exports.formatPickOutcomeType = formatPickOutcomeType;
var PickType;
(function (PickType) {
    PickType[PickType["AwayMoneyline"] = 0] = "AwayMoneyline";
    PickType[PickType["HomeMoneyline"] = 1] = "HomeMoneyline";
    PickType[PickType["AwaySpread"] = 2] = "AwaySpread";
    PickType[PickType["HomeSpread"] = 3] = "HomeSpread";
    PickType[PickType["Over"] = 4] = "Over";
    PickType[PickType["Under"] = 5] = "Under";
})(PickType || (exports.PickType = PickType = {}));
/** Swaps the alignment of the pick and updates its type to match. */
function changePickAlignment(input) {
    input.alignment === game_1.GameAlignmentType.Away
        ? (input.alignment = game_1.GameAlignmentType.Home)
        : (input.alignment = game_1.GameAlignmentType.Away);
    if (input.alignment === game_1.GameAlignmentType.Away) {
        switch (input.odds[game_1.GameAlignmentType.Away].type) {
            case LineType.Moneyline:
                input.type = 0;
                break;
            case LineType.Spread:
                input.type = 2;
                break;
            case LineType.Total:
                input.type = 4;
                break;
        }
    }
    else {
        switch (input.odds[game_1.GameAlignmentType.Home].type) {
            case LineType.Moneyline:
                input.type = 1;
                break;
            case LineType.Spread:
                input.type = 3;
                break;
            case LineType.Total:
                input.type = 5;
                break;
        }
    }
}
exports.changePickAlignment = changePickAlignment;
function getPickAlignment(input) {
    switch (input) {
        case 0:
        case 2:
        case 4:
            return game_1.GameAlignmentType.Away;
        default:
            return game_1.GameAlignmentType.Home;
    }
}
exports.getPickAlignment = getPickAlignment;
function getOpposingPickAlignment(input) {
    switch (input) {
        case 0:
        case 2:
        case 4:
            return game_1.GameAlignmentType.Home;
        default:
            return game_1.GameAlignmentType.Away;
    }
}
exports.getOpposingPickAlignment = getOpposingPickAlignment;
function isSameLineType(a, b) {
    switch (a.type) {
        case 0:
        case 1:
            switch (b.type) {
                case 0:
                case 1:
                    return true;
            }
            return false;
        case 2:
        case 3:
            switch (b.type) {
                case 2:
                case 3:
                    return true;
            }
            return false;
        case 4:
        case 5:
            switch (b.type) {
                case 4:
                case 5:
                    return true;
            }
            return false;
    }
}
exports.isSameLineType = isSameLineType;
function lineToPickType(line, alignment) {
    switch (line) {
        case 0:
            return alignment === 0 ? 0 : 1;
        case 1:
            return alignment === 0 ? 2 : 3;
        case 2:
            return alignment === 0 ? 4 : 5;
    }
}
exports.lineToPickType = lineToPickType;
/** Infer the risk and gain of units based on the alignment and payout. */
function determineUnitRiskGain(pick) {
    const payout = pick.odds[pick.alignment].payout;
    if (payout > 0) {
        return { risk: 100, gain: payout };
    }
    return { risk: -payout, gain: 100 };
}
exports.determineUnitRiskGain = determineUnitRiskGain;
/** Prepares a recent pick to be sent to the backend. */
function createPickPayload(bettor, pick) {
    return {
        global_game_id: pick.game.global_game_id,
        type: pick.type,
        bettor_id: bettor,
        sport_type: pick.sport_type,
    };
}
exports.createPickPayload = createPickPayload;
function pickToOdd(pick) {
    return {
        type: pickTypeToLineType(pick.type),
        alignment: getPickAlignment(pick.type),
        value: pick.oddValue,
        payout: pick.oddPayout,
    };
}
exports.pickToOdd = pickToOdd;
function pickToOpposingOdd(pick) {
    return {
        type: pickTypeToLineType(pick.type),
        alignment: getPickAlignment(pick.type) === 0 ? 1 : 0,
        value: pick.oddValue ? -pick.oddValue : undefined,
        payout: pick.opposingOddPayout,
    };
}
exports.pickToOpposingOdd = pickToOpposingOdd;
function pickTypeToLineType(input) {
    switch (input) {
        case 0:
        case 1:
            return LineType.Moneyline;
        case 2:
        case 3:
            return LineType.Spread;
        default:
            return LineType.Total;
    }
}
exports.pickTypeToLineType = pickTypeToLineType;
var OddOutcome;
(function (OddOutcome) {
    OddOutcome[OddOutcome["Win"] = 0] = "Win";
    OddOutcome[OddOutcome["Loss"] = 1] = "Loss";
    OddOutcome[OddOutcome["Push"] = 2] = "Push";
})(OddOutcome || (exports.OddOutcome = OddOutcome = {}));
function formatOddVariant(odd) {
    switch (odd) {
        case "spread":
            return "Spread";
        case "moneyline":
            return "Moneyline";
        case "over":
        case "under":
        case "total":
            return "Total (O/U)";
        default:
            return "All Odds";
    }
}
exports.formatOddVariant = formatOddVariant;
function formatOddVariantShort(odd) {
    switch (odd) {
        case "spread":
            return "ATS";
        case "moneyline":
            return "ML";
        case "over":
            return "OVR";
        case "under":
            return "UND";
        case "total":
            return "O/U";
        default:
            return "ALL";
    }
}
exports.formatOddVariantShort = formatOddVariantShort;
function formatOddOutcome(outcome) {
    switch (outcome) {
        case OddOutcome.Win:
            return "win";
        case OddOutcome.Loss:
            return "loss";
        default:
            return "push";
    }
}
exports.formatOddOutcome = formatOddOutcome;
function formatOddOutcomeShort(outcome) {
    switch (outcome) {
        case OddOutcome.Win:
            return "w";
        case OddOutcome.Loss:
            return "l";
        default:
            return "p";
    }
}
exports.formatOddOutcomeShort = formatOddOutcomeShort;
function oddOutcomeColor(outcome) {
    switch (outcome) {
        case OddOutcome.Win:
            return "text-success-300";
        case OddOutcome.Loss:
            return "text-error-300";
        default:
            return "text-orange";
    }
}
exports.oddOutcomeColor = oddOutcomeColor;
function formatOddEnum(outcome) {
    switch (outcome) {
        case "win":
            return 0;
        case "loss":
            return 1;
        case "push":
            return 2;
    }
}
exports.formatOddEnum = formatOddEnum;
function buildOdd(type, alignment, odds) {
    if (odds) {
        let value = undefined;
        let payout;
        let outcome = undefined;
        switch (type) {
            case LineType.Moneyline:
                if (alignment === game_1.GameAlignmentType.Away) {
                    payout = odds.awayMoneyline,
                        outcome = odds.awayMoneylineOutcome;
                }
                else {
                    payout = odds.homeMoneyline,
                        outcome = odds.homeMoneylineOutcome;
                }
                break;
            case LineType.Spread:
                if (alignment === game_1.GameAlignmentType.Away) {
                    value = odds.awaySpread,
                        payout = odds.awaySpreadPayout,
                        outcome = odds.awaySpreadOutcome;
                }
                else {
                    value = odds.homeSpread,
                        payout = odds.homeSpreadPayout,
                        outcome = odds.homeSpreadOutcome;
                }
                break;
            case LineType.Total:
                if (alignment === game_1.GameAlignmentType.Away) {
                    value = odds.overUnder,
                        payout = odds.overPayout,
                        outcome = odds.overOutcome;
                }
                else {
                    value = odds.overUnder,
                        payout = odds.underPayout,
                        outcome = odds.underOutcome;
                }
                break;
        }
        return {
            type,
            alignment,
            value,
            payout,
            outcome
        };
    }
    else {
        return undefined;
    }
}
exports.buildOdd = buildOdd;
