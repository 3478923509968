"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SEASON_DURATION = exports.createServerSeason = exports.toSeasonTag = exports.SEASONS = exports.toSeasonType = exports.formatServerSeason = exports.getDefaultYear = exports.getDefaultSeason = exports.formatSeason = exports.formatSport = exports.toSportType = exports.ALL_SPORT_TYPES = exports.SportType = exports.SPORTS = exports.SPORT_TYPE_OPTIONS = void 0;
exports.SPORT_TYPE_OPTIONS = [
    { value: "0", label: "nfl" },
    { value: "1", label: "nba" },
    { value: "2", label: "mlb" },
    { value: "3", label: "nhl" },
];
exports.SPORTS = ["nfl", "nba", "mlb", "nhl"];
var SportType;
(function (SportType) {
    SportType[SportType["Nfl"] = 0] = "Nfl";
    SportType[SportType["Nba"] = 1] = "Nba";
    SportType[SportType["Mlb"] = 2] = "Mlb";
    SportType[SportType["Nhl"] = 3] = "Nhl";
})(SportType || (exports.SportType = SportType = {}));
exports.ALL_SPORT_TYPES = [
    SportType.Nfl,
    SportType.Nba,
    SportType.Mlb,
    SportType.Nhl,
];
function toSportType(input) {
    switch (input) {
        case "nfl":
            return SportType.Nfl;
        case "nba":
            return SportType.Nba;
        case "mlb":
            return SportType.Mlb;
        case "nhl":
            return SportType.Nhl;
        default:
            console.error(`${input} is not a valid SportType`);
    }
}
exports.toSportType = toSportType;
function formatSport(input) {
    switch (input) {
        case SportType.Nfl:
            return "nfl";
        case SportType.Nba:
            return "nba";
        case SportType.Mlb:
            return "mlb";
        default:
            return "nhl";
    }
}
exports.formatSport = formatSport;
// export function formatSeason(sport: Sport, season: Season): string {
//   switch (sport) {
//     case "nfl":
//       switch (season) {
//         case "pre-season":
//           return "Pre Season";
//         case "regular-season":
//           return "Regular Season";
//         case "post-season":
//           return "Post Season";
//       }
//     case "nba":
//       break;
//     case "nhl":
//       break;
//     case "mlb":
//       break;
//   }
// }
function formatSeason(season) {
    switch (season) {
        case "preseason":
            return "Pre Season";
        case "regular-season":
            return "Regular Season";
        case "postseason":
            return "Post Season";
        case "offseason":
            return "Off Season";
        case "all-star":
            return "All Star";
    }
}
exports.formatSeason = formatSeason;
function getDefaultSeason(sport) {
    switch (sport) {
        case "nfl":
            return "preseason";
        case "nba":
            return "offseason";
        case "mlb":
            return "regular-season";
        case "nhl":
            return "offseason";
    }
}
exports.getDefaultSeason = getDefaultSeason;
function getDefaultYear(sport) {
    switch (sport) {
        case "mlb":
            return "2024";
        case "nba":
            return "2025";
        case "nhl":
            return "2025";
        case "nfl":
            return "2024";
    }
}
exports.getDefaultYear = getDefaultYear;
function formatServerSeason(season, clamped) {
    switch (season) {
        case 1:
            return "regular-season";
        case 2:
            return "preseason";
        case 3:
            return "postseason";
        case 4:
            if (clamped)
                return "postseason";
            if (!clamped)
                return "offseason";
        case 5:
            return "all-star";
    }
}
exports.formatServerSeason = formatServerSeason;
function toSeasonType(season) {
    switch (season) {
        case "regular-season":
            return 1;
        case "preseason":
            return 2;
        case "postseason":
            return 3;
        case "offseason":
            return 4;
        default:
            return 1;
    }
}
exports.toSeasonType = toSeasonType;
exports.SEASONS = ["PRE", "REG", "POST"];
function toSeasonTag(season) {
    switch (season) {
        case 2:
            return "PRE";
        case 3:
            return "POST";
        default:
            return "REG";
    }
}
exports.toSeasonTag = toSeasonTag;
function createServerSeason(year, season) {
    switch (season) {
        case 2:
            return `${year}PRE`;
        case 1:
            return `${year}REG`;
        case 3:
            return `${year}POST`;
    }
}
exports.createServerSeason = createServerSeason;
/**
 * The durations of the most recent seasons for each sport.
 */
exports.SEASON_DURATION = {
    nfl: {
        start: "2024-09-06T00:20:00Z",
        end: "2024-02-12",
    },
    nba: {
        start: "2024-10-22T23:30:00Z",
        end: "2024-06-18",
    },
    mlb: {
        start: "2024-03-20",
        end: "2023-11-01",
    },
    nhl: {
        start: "2024-10-04T17:00:00Z",
        end: "2024-06-25",
    },
};
