"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatSeasonType = exports.isGameException = exports.isGameClosed = exports.GameOutcomeType = exports.GameAlignmentType = void 0;
var GameAlignmentType;
(function (GameAlignmentType) {
    GameAlignmentType[GameAlignmentType["Away"] = 0] = "Away";
    GameAlignmentType[GameAlignmentType["Home"] = 1] = "Home";
})(GameAlignmentType || (exports.GameAlignmentType = GameAlignmentType = {}));
var GameOutcomeType;
(function (GameOutcomeType) {
    GameOutcomeType[GameOutcomeType["Away"] = 0] = "Away";
    GameOutcomeType[GameOutcomeType["Home"] = 1] = "Home";
    GameOutcomeType[GameOutcomeType["Tie"] = 2] = "Tie";
})(GameOutcomeType || (exports.GameOutcomeType = GameOutcomeType = {}));
function isGameClosed(status) {
    switch (status) {
        case "Scheduled":
        case "InProgress":
            return false;
        default:
            return true;
    }
}
exports.isGameClosed = isGameClosed;
function isGameException(status) {
    switch (status) {
        case "Postponed":
        case "Delayed":
        case "Canceled":
            return true;
        default:
            return false;
    }
}
exports.isGameException = isGameException;
function formatSeasonType(seasonType) {
    switch (seasonType) {
        case 1:
            return "Regular Season";
        case 2:
            return "Preseason";
        case 3:
            return "Postseason";
        case 4:
            return "Offseason";
        case 5:
            return "AllStar";
    }
}
exports.formatSeasonType = formatSeasonType;
